
import {Tag} from "../types/models";
import VueProgressBar from "./progress-bar.vue";

export default {
  name: 'VueTags',
  components: {'vue-progress-bar': VueProgressBar},
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setPriority(tag: Tag, priority: number) {
      tag.priority = priority;
      this.$emit('change', tag);
    },
    deleteTag(tag: Tag) {
      this.$emit('delete', tag);
    },
  },
  computed: {
    tagName() {
      return this.editable ? 'span' : 'a';
    },
  },
};
