import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tag-clouds"
}
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_progress_bar = _resolveComponent("vue-progress-bar")!

  return ($props.tags.length)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tags, (tag) => {
          return (_openBlock(), _createElementBlock("li", null, [
            (_openBlock(), _createBlock(_resolveDynamicComponent($options.tagName), {
              href: tag.url
            }, {
              default: _withCtx(() => [
                (tag.logo)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      alt: tag.name,
                      src: tag.logo
                    }, null, 8 /* PROPS */, _hoisted_2))
                  : _createCommentVNode("v-if", true),
                _createTextVNode(" " + _toDisplayString(tag.real_name || tag.name) + " ", 1 /* TEXT */),
                ($props.editable)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      onClick: ($event: any) => ($options.deleteTag(tag)),
                      class: "remove"
                    }, _cache[0] || (_cache[0] = [
                      _createElementVNode("i", { class: "fa fa-xmark" }, null, -1 /* HOISTED */)
                    ]), 8 /* PROPS */, _hoisted_3))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["href"])),
            ($props.editable || tag.priority)
              ? (_openBlock(), _createBlock(_component_vue_progress_bar, {
                  key: 0,
                  editable: $props.editable,
                  modelValue: tag.priority,
                  "onUpdate:modelValue": ($event: any) => ((tag.priority) = $event),
                  onClick: value => $options.setPriority(tag, value)
                }, null, 8 /* PROPS */, ["editable", "modelValue", "onUpdate:modelValue", "onClick"]))
              : _createCommentVNode("v-if", true)
          ]))
        }), 256 /* UNKEYED_FRAGMENT */))
      ]))
    : _createCommentVNode("v-if", true)
}