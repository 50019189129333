
import axios from 'axios';
import IsImage from '../libs/assets';

export default {
  name: 'VueThumbnail',
  props: {
    url: {
      type: String,
      required: false,
    },
    uploadUrl: {
      type: String,
      default: '/assets',
    },
    name: {
      type: String,
      default: 'photo',
    },
    onlyImage: {
      type: Boolean,
      default: false,
    },
    openOnMount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isProcessing: false,
    };
  },
  mounted() {
    if (this.$props.openOnMount) {
      this.openDialog();
    }
  },
  methods: {
    upload() {
      let formData = new FormData();
      formData.append(this.name, this.$refs.input.files[0]);

      this.isProcessing = true;

      const config = {
        onUploadProgress: event => {
          this.$emit('progress', Math.round((event.loaded * 100) / event.total));
        },
      };

      return axios.post(this.uploadUrl, formData, config)
        .then(response => this.$emit('upload', response.data))
        .finally(() => {
          this.isProcessing = false;
        });
    },
    deleteImage() {
      this.$emit('delete', this.url);
    },
    insertImage() {
      this.$emit('insert', this.url);
    },
    openDialog() {
      this.$refs.input.click();
    },
  },
  computed: {
    isImage() {
      return IsImage(this.url);
    },
    accept() {
      return this.onlyImage ? 'image/jpeg,image/png,image/gif' : '';
    },
  },
};

