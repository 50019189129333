import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "btn-toolbar ms-auto"
}
const _hoisted_2 = {
  class: "btn-group d-inline me-2 ms-2 mt-1",
  role: "group",
  "aria-label": "..."
}
const _hoisted_3 = ["onClick", "title"]
const _hoisted_4 = { style: {"position":"relative"} }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "emoji-picker-container" }
const _hoisted_7 = { class: "pt-1 ps-2 pe-2 d-flex" }
const _hoisted_8 = { class: "small me-auto" }
const _hoisted_9 = { class: "small" }
const _hoisted_10 = ["aria-label"]
const _hoisted_11 = {
  key: 0,
  class: "pt-3 pb-3 ps-2 pe-2 d-flex"
}
const _hoisted_12 = {
  id: "js-wiki-help",
  class: "mt-2 collapse"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_tabs = _resolveComponent("vue-tabs")!
  const _component_vue_editor = _resolveComponent("vue-editor")!
  const _component_vue_error = _resolveComponent("vue-error")!
  const _component_vue_emoji_picker = _resolveComponent("vue-emoji-picker")!
  const _component_vue_thumbnail = _resolveComponent("vue-thumbnail")!
  const _component_vue_help = _resolveComponent("vue-help")!
  const _directive_paste = _resolveDirective("paste")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'is-invalid': $props.isInvalid}, "editor"])
  }, [
    _createVNode(_component_vue_tabs, {
      onChange: $options.switchTab,
      items: $data.tabs,
      "current-tab": $data.tabs.indexOf($data.currentTab),
      type: "pills",
      class: "mb-2"
    }, {
      default: _withCtx(() => [
        ($options.isContent)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.buttons, (button) => {
                  return (_openBlock(), _createElementBlock("button", {
                    onClick: button.click,
                    type: "button",
                    class: "btn btn-sm btn-control",
                    title: button.can ? button.title : button.break,
                    style: _normalizeStyle({opacity: button.can ? '1.0' : '0.4', cursor: button.can ? 'pointer' : 'default'})
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(['fas fa-fw', button.icon])
                    }, null, 2 /* CLASS */)
                  ], 12 /* STYLE, PROPS */, _hoisted_3))
                }), 256 /* UNKEYED_FRAGMENT */))
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onChange", "items", "current-tab"]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("div", null, [
        _createElementVNode("div", {
          class: _normalizeClass(['form-control', {'is-invalid': $props.error !== null}]),
          style: {"height":"inherit","outline":"none","box-shadow":"none","border":"none","padding-left":"0","padding-right":"0"}
        }, [
          _withDirectives(_createVNode(_component_vue_editor, {
            ref: "editor",
            modelValue: _ctx.valueLocal,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueLocal) = $event)),
            placeholder: "Kliknij, aby dodać treść...",
            autocompleteSource: $options.autocomplete,
            emojiUrl: $options.emojiUrl,
            emojis: $options.emojisProperty,
            onSubmit: $options.save,
            onCancel: $options.cancel,
            onState: $options.updateState
          }, null, 8 /* PROPS */, ["modelValue", "autocompleteSource", "emojiUrl", "emojis", "onSubmit", "onCancel", "onState"]), [
            [_directive_paste, $options.addAsset, "success"]
          ])
        ], 2 /* CLASS */),
        _createVNode(_component_vue_error, { message: $props.error }, null, 8 /* PROPS */, ["message"])
      ], 512 /* NEED_PATCH */), [
        [_vShow, $options.isContent]
      ]),
      _withDirectives(_createElementVNode("div", {
        innerHTML: $data.previewHtml,
        class: "preview post-content"
      }, null, 8 /* PROPS */, _hoisted_5), [
        [_vShow, $options.isPreview]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_6, [
        _createVNode(_component_vue_emoji_picker, {
          ref: "emoji-picker",
          emojis: $props.emojis,
          open: $data.emojiPickerOpen,
          onInput: $options.insertEmoji,
          onBlur: $options.blurEmojiPicker,
          onClose: $options.closeEmojiPicker
        }, null, 8 /* PROPS */, ["emojis", "open", "onInput", "onBlur", "onClose"])
      ], 512 /* NEED_PATCH */), [
        [_vShow, $data.emojiPickerOpen]
      ])
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("hr", { class: "m-0" }, null, -1 /* HOISTED */)),
    _renderSlot(_ctx.$slots, "bottom"),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        ($data.isProcessing)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fas fa-spinner fa-spin small" }, null, -1 /* HOISTED */)),
              _createElementVNode("span", _hoisted_9, _toDisplayString($data.progress) + "%", 1 /* TEXT */)
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              "aria-label": $options.uploadTooltip,
              tabindex: "-1",
              "data-balloon-length": "large",
              "data-balloon-pos": "up-left",
              "data-balloon-nofocus": "",
              href: "javascript:",
              class: "small text-muted",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.chooseFile && $options.chooseFile(...args)))
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("i", { class: "far fa-image" }, null, -1 /* HOISTED */),
              _createTextVNode(" " + _toDisplayString(' ') + " "),
              _createElementVNode("span", { class: "d-none d-sm-inline" }, "Kliknij, aby dodać załącznik lub wklej ze schowka.", -1 /* HOISTED */)
            ]), 8 /* PROPS */, _hoisted_10)),
        _renderSlot(_ctx.$slots, "options")
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "small ms-auto" }, [
        _createElementVNode("a", {
          href: "#js-wiki-help",
          tabindex: "-1",
          "data-bs-toggle": "collapse",
          class: "small text-muted"
        }, [
          _createElementVNode("i", { class: "fab fa-markdown" }),
          _createTextVNode(" Instrukcja obsługi Markdown ")
        ])
      ], -1 /* HOISTED */))
    ]),
    ($props.assets.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.assets, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "col-sm-2"
            }, [
              _createVNode(_component_vue_thumbnail, {
                name: "asset",
                "data-balloon-pos": "down",
                url: item.url,
                "aria-label": item.name,
                onDelete: ($event: any) => ($options.deleteAsset(item)),
                onInsert: ($event: any) => ($options.insertAssetAtCaret(item))
              }, null, 8 /* PROPS */, ["url", "aria-label", "onDelete", "onInsert"])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_vue_help)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2 /* CLASS */))
}