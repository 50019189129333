import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "thumbnail" }
const _hoisted_2 = { class: "position-relative img-thumbnail text-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "placeholder-mask"
}
const _hoisted_5 = {
  key: 1,
  class: "bg-light placeholder-mask"
}
const _hoisted_6 = {
  key: 0,
  class: "fas fa-circle-plus fa-2x"
}
const _hoisted_7 = {
  key: 4,
  class: "spinner-mask"
}
const _hoisted_8 = ["accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.url)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            ($options.isImage)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: $props.url,
                  class: "mw-100"
                }, null, 8 /* PROPS */, _hoisted_3))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [
                  _createElementVNode("i", { class: "far fa-file fa-2x" }, null, -1 /* HOISTED */)
                ])))
          ], 64 /* STABLE_FRAGMENT */))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (!$data.isProcessing)
              ? (_openBlock(), _createElementBlock("i", _hoisted_6))
              : _createCommentVNode("v-if", true)
          ])),
      ($props.url)
        ? (_openBlock(), _createElementBlock("a", {
            key: 2,
            href: "javascript:",
            class: "thumbnail-mask",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.insertImage && $options.insertImage(...args)))
          }))
        : _createCommentVNode("v-if", true),
      ($props.url)
        ? (_openBlock(), _createElementBlock("a", {
            key: 3,
            href: "javascript:",
            class: "btn btn-sm btn-danger delete",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.deleteImage && $options.deleteImage(...args))),
            title: "Usuń"
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("i", { class: "fas fa-xmark" }, null, -1 /* HOISTED */)
          ])))
        : _createCommentVNode("v-if", true),
      ($data.isProcessing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[5] || (_cache[5] = [
            _createElementVNode("i", { class: "fas fa-spinner fa-spin fa-2x" }, null, -1 /* HOISTED */)
          ])))
        : _createCommentVNode("v-if", true),
      _withDirectives(_createElementVNode("input", {
        onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.upload && $options.upload(...args))),
        accept: $options.accept,
        class: "thumbnail-mask",
        type: "file",
        ref: "input"
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_8), [
        [_vShow, !$props.url && !$data.isProcessing]
      ])
    ])
  ]))
}